body {
  background: linear-gradient(135deg, hsl(170, 100%, 50%), hsl(260, 100%, 35%));
  background-attachment: fixed;
  margin: 0;
  font-size: 10px;
}

#root {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.App-main {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Catamaran', Arial, sans-serif;
  /* background: hsla(0, 0%, 0%, 0.25); */
  text-align: center;
}

@media only screen and (max-width: 812px) {
  .App-main {
    width: 80vw;
    height: 45vw;
    border-radius: 2vw;
  }

  .title--largest {
    color: hsl(0, 0%, 85%);
    font-weight: bold;
    letter-spacing: 0.5vw;
    font-size: 8vw;
  }

  .title--large {
    color: hsl(0, 0%, 70%);
    font-weight: 500;
    letter-spacing: 0.25vw;
    font-size: 4vw;
  }
}

@media only screen and (min-width: 813px) {
  .App-main {
    width: 800px;
    height: 450px;
    border-radius: 20px;
  }
  .title--largest {
    color: hsl(0, 0%, 85%);
    font-weight: bold;
    letter-spacing: 0.5rem;
    font-size: 5rem;
  }

  .title--large {
    color: hsl(0, 0%, 70%);
    font-weight: 500;
    letter-spacing: 0.25rem;
    font-size: 3rem;
  }
}
